import { Collapse, Grid, IconButton, Popover, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import TableNoFilter from './TableNoFilter';
import ApiService from '../../services/ApiService';
import TableBalance from './TableBalance';
import { Icon, showToast } from '../ui';
import { defaultDate, updateDateFormat } from '../../utils/date';
import { handleDataTable } from '../../utils/reusableFunction';

const BodyComponent = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const [openChild, setOpenChild] = useState(props.el.isDelivered);
    const transactionNo = props.source !== 'TR' && props.source !== 'TP' ? props.el.reference : props.source === 'TR' ? props.el.trNo2 : props.el.tpNo2;
    const screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const customScreenWidth = screenWidth - (screenWidth * 7.5 / 100);
    const maxHeight = '250px';

    const [openGL, setOpenGL] = useState(true);
    const [openCB, setOpenCB] = useState(false);

    const [dataMapGL, setDataMapGL] = useState([]);
    const [dataTableGL, setDataTableGL] = useState([]);
    const [sortArrGL, setSortArrGL] = useState([]);
    const [rowsCountGL, setRowsCountGL] = useState(50);
    const [columnDataGL, setColumnDataGL] = useState([]);
    const [SelectedDataGL, setSelectedDataGL] = useState({});

    const [DataCB, setDataCB] = useState([]);

    function handleOpen(rData) {
        let id = 0;
        let dateGL = new Date();

        if (props.source === 'INV') {
            id = rData.id;
            dateGL = updateDateFormat(rData.printedOn);
        }
        else if (props.source === 'PR') {
            id = rData.id;
            dateGL = updateDateFormat(rData.printedOn);
        }
        else if (props.source === 'OR') {
            console.log(rData)
            id = rData.orId;
            dateGL = updateDateFormat(rData.approvedAccOn);
        }
        else if (props.source === 'PV') {
            id = rData.pvId;
            dateGL = updateDateFormat(rData.approvedAccOn);
        }
        else if (props.source === 'RV') {
            id = rData.rvId;
            dateGL = updateDateFormat(rData.approvedAccOn);
        }
        else if (props.source === 'TP') {
            id = rData.tpId;
            dateGL = updateDateFormat(rData.approvedAccOn);
        }
        else if (props.source === 'TR') {
            id = rData.trId;
            dateGL = updateDateFormat(rData.approvedAccOn);
        }
        else if (props.source === 'ME') {
            id = rData.id;
            dateGL = updateDateFormat(rData.entryDate);
        }
        else if (props.source === 'ORPPH') {
            id = rData.id;
            dateGL = updateDateFormat(rData.receivedOn);
        }

        if (!openChild === true) {
            props.setIsLoadingBackDrop(true);
            let params = [
                {
                    "attr": "refId",
                    "value": "" + id
                },
                {
                    'attr': 'source',
                    'value': '' + props.source
                }
            ];

            ApiService.FinAccPostByPage('generalLedger/generalLedger', 'PAGE', 1, 50, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataGL({})

                        let temp = response.data.data.generalLedger
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapGL(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableGL, 'id');
                        setColumnDataGL(response.data.data.columns)

                        ApiService.RegClosingPeriodPostById('regClosingPeriod/regClosingPeriod', dateGL)
                            .then((response) => {
                                if (response.data.code === 200) {
                                    let data = response.data.data.closingPeriod;

                                    let params = [
                                        {
                                            "attr": "refId",
                                            "value": "" + id
                                        },
                                        {
                                            'attr': 'source',
                                            'value': '' + props.source
                                        },
                                        {
                                            'attr': 'period',
                                            'value': '' + data.period
                                        },
                                        {
                                            'attr': 'yearPeriod',
                                            'value': '' + data.yearPeriod
                                        }
                                    ];

                                    ApiService.FinAccPostByPage('closingBalance/closingBalance', 'LOG', 1, 50, params, [])
                                        .then((response) => {
                                            if (response.data.code === 200) {
                                                let temp = response.data.data.closingBalances
                                                let indexed = temp.map((el, index) => {
                                                    let indexedTemp = {
                                                        ...el,
                                                        index
                                                    }
                                                    return indexedTemp
                                                })

                                                setDataCB(indexed);

                                                setOpenChild(!openChild);

                                                let data = JSON.parse(localStorage.getItem(props.localVar));
                                                handleChild(openChild, rData, data);
                                                localStorage.setItem(props.localVar, JSON.stringify(data));
                                                props.setDataMap(data);

                                                props.setIsLoadingBackDrop(false);
                                            }
                                            else if (response.data.code === 500) {
                                                showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });

                                                setOpenChild(!openChild);

                                                let data = JSON.parse(localStorage.getItem(props.localVar));
                                                handleChild(openChild, rData, data);
                                                localStorage.setItem(props.localVar, JSON.stringify(data));
                                                props.setDataMap(data);

                                                props.setIsLoadingBackDrop(false);
                                            }
                                        })
                                        .catch(function (error) {
                                            console.error('error saat fetch', error)
                                            // Alert.ErrorAlert(error.toString(), true)
                                            showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });

                                            setOpenChild(!openChild);

                                            let data = JSON.parse(localStorage.getItem(props.localVar));
                                            handleChild(openChild, rData, data);
                                            localStorage.setItem(props.localVar, JSON.stringify(data));
                                            props.setDataMap(data);

                                            props.setIsLoadingBackDrop(false);
                                        })
                                }
                            })
                            .catch(function (error) {
                                // console.error('error saat fetch', error);

                                setOpenChild(!openChild);

                                let data = JSON.parse(localStorage.getItem(props.localVar));
                                handleChild(openChild, rData, data);
                                localStorage.setItem(props.localVar, JSON.stringify(data));
                                props.setDataMap(data);

                                props.setIsLoadingBackDrop(false);
                            })
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });

                        setOpenChild(!openChild);

                        let data = JSON.parse(localStorage.getItem(props.localVar));
                        handleChild(openChild, rData, data);
                        localStorage.setItem(props.localVar, JSON.stringify(data));
                        props.setDataMap(data);

                        props.setIsLoadingBackDrop(false);
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });

                    setOpenChild(!openChild);

                    let data = JSON.parse(localStorage.getItem(props.localVar));
                    handleChild(openChild, rData, data);
                    localStorage.setItem(props.localVar, JSON.stringify(data));
                    props.setDataMap(data);

                    props.setIsLoadingBackDrop(false);
                })
        }
        else {
            props.setIsLoadingBackDrop(false);

            let data = JSON.parse(localStorage.getItem(props.localVar));
            handleChild(openChild, rData, data);
            localStorage.setItem(props.localVar, JSON.stringify(data));
            props.setDataMap(data);

            setOpenChild(!openChild);
        }
    }

    function handleChild(openChild, rData, data) {
        data.forEach(element => {
            if (element.id === rData.id) {
                element.isDelivered = !openChild;
            }
        });
    }

    const formatValue = (value, dataType) => {
        if (dataType === 'datetime' || dataType === 'date') {
            if (value !== "0001-01-01T00:00:00" && new Date(value).getFullYear() !== new Date(defaultDate).getFullYear()) {
                const tempDate = new Date(value);
                return tempDate.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
            } else {
                return '-';
            }
        } else if (dataType === 'currency') {
            return new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(`${value}`);
        } else if (value === true) {
            return 'Yes';
        } else if (value === false) {
            return 'No';
        } else {
            return value;
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = useRef(null);

    return (
        <>
            <tr
                key={props.index}
                className={props.tempStyle}
                onClick={() => props.handleClick(props.dataMap[props.index])}
                onDoubleClick={() => props.handleDoubleClick(props.dataMap[props.index])}
                style={{ fontSize: '14px', background: '#FFF', padding: '24px 8px !important', alignItems: 'center', height: '27.09px', borderBottom: '1px solid #FAFAFA' }}
            >
                {
                    props.dataMap[props.index].isPosted && props.dataMap[props.index].rowStatus !== 'DEL' ?
                        <td style={{ verticalAlign: 'middle' }}>
                            <IconButton
                                aria-label="expand row"
                                size='small'
                                onClick={() => handleOpen(props.el)}
                            >
                                {
                                    openChild ?
                                        <Icon
                                            iconName={'arrow-up'}
                                            svgIconProps={{
                                                htmlColor: '#00286D',
                                                sx: {
                                                    width: '10px',
                                                    height: '10px',
                                                }
                                            }}
                                        />
                                        :
                                        <Icon
                                            iconName={'arrow-down'}
                                            svgIconProps={{
                                                htmlColor: '#00286D',
                                                sx: {
                                                    width: '10px',
                                                    height: '10px',
                                                }
                                            }}
                                        />
                                }
                            </IconButton>
                        </td>
                        :
                        <td />
                }

                {
                    Object.keys(props.el).map((headersEl, index) => {
                        const temp = formatValue(props.el[headersEl], props.columnData.find(el => el.column === headersEl)?.dataType);
                        const dataType = props.columnData.find(el => el.column === headersEl)?.dataType;

                        if (headersEl === props.uniqueKey && props.columnData.find(el => el.column === headersEl)?.hidden) return null;

                        if (dataType?.includes('custom') === false) {
                            if (headersEl === 'rowStatus') {
                                return (
                                    <td
                                        key={index}
                                        align={props.columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '4px',
                                                display: 'flex',
                                                width: 'fit-content',
                                                alignItems: 'center',
                                                borderRadius: '32px',
                                                justifyContent: 'center',
                                                background: temp?.trim() === 'ACT' ? 'var(--Green-Button, #168444)' : 'var(--Red-Alert, #D20C0C)',
                                            }}
                                        >
                                            <Icon
                                                iconName={temp?.trim() === 'ACT' ? 'active' : 'inactive'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#EAEAEA',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                )
                            }
                            else if (headersEl === 'documentStatus' && temp !== '' && temp !== undefined) {
                                return (
                                    <td
                                        key={index}
                                        align={props.columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '75px',
                                                alignItems: 'center',
                                                borderRadius: '32px',
                                                padding: '4px 12px 4px',
                                                justifyContent: 'center',
                                                border: temp === 'Submitted' ? '1.5px solid var(--Orange-Outline-1, #FEA51E)' : temp === 'Draft' ? '1.5px solid #585858' : '1.5px solid #007330',
                                                background: temp === 'Submitted' ? 'rgba(254, 165, 30, 0.15)' : temp === 'Draft' ? 'rgba(88, 88, 88, 0.15)' : 'rgba(0, 115, 48, 0.15)',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontWeight: '600',
                                                    fontStyle: 'normal',
                                                    fontFamily: 'Nunito',
                                                    lineHeight: '12px !important',
                                                    color: temp === 'Submitted' ? 'var(--Orange-Outline-1, #FEA51E)' : temp === 'Draft' ? '#585858' : '#007330',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>
                                    </td>
                                )
                            }
                            else if (headersEl === 'glStatusName' || headersEl === 'glStatus') {
                                return (
                                    <td
                                        key={index}
                                        align={'center'}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                gap: '10px',
                                                height: '18px',
                                                padding: '10px',
                                                display: 'flex',
                                                borderRadius: '4px',
                                                alignItems: 'center',
                                                width: 'fit-content',
                                                justifyContent: 'center',
                                                background: temp === 'Failed' ? 'var(--Mjr-blue-4, #BE2957)' : 'transparent',
                                                boxShadow: isHovered && temp === 'Failed' ? '0px 4px 4px 0px rgba(0, 0, 0, 0.20)' : null,
                                            }}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                            disabled={temp === 'Failed' ? false : true}
                                            onClick={() => temp === 'Failed' ? props.handleFailedGL(props.el) : null}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '11px',
                                                    userSelect: 'none',
                                                    fontStyle: 'normal',
                                                    fontFamily: 'Nunito',
                                                    lineHeight: 'normal',
                                                    fontWeight: temp === 'Failed' ? '700' : '400',
                                                    cursor: temp === 'Failed' ? 'pointer' : 'default',
                                                    textDecorationLine: temp === 'Failed' ? 'underline' : 'none',
                                                    color: temp === 'Success' ? '#168444' : temp === 'Failed' ? '#FFFFFF' : temp === 'On Progress' ? '#F68C00' : '#F68C00',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>
                                    </td>
                                )
                            }
                            else {
                                return (
                                    <td
                                        key={index}
                                        align={props.columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <Typography
                                            className={props.tempTextStyle}
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontFamily: 'Switzer',
                                                fontSize: '10px',
                                                fontStyle: 'normal',
                                                lineHeight: '140%',
                                                letterSpacing: '0.1px',
                                            }}
                                        >
                                            {temp}
                                        </Typography>
                                    </td>
                                )
                            }
                        }
                        else {
                            const uniqueMark = props.uniqueCol?.includes('-') ? props.uniqueCol?.split('-')[0] : props.uniqueCol
                            if (!dataType?.includes(uniqueMark)) return null;

                            const customData = {
                                statusName: {
                                    'Ready to Request': {
                                        textColor: '#FFF',
                                        backgroundColor: '#6692DE',
                                        isDropDown: false,
                                    },
                                    'Requested': {
                                        textColor: '#F68C00',
                                        backgroundColor: '#FEF3E5',
                                        isDropDown: !props.uniqueCol.includes('Request') && !props.uniqueCol.includes('Import') && props.tableAccess?.[props.uniqueCol]?.Requested.access ? true : false,
                                        dropDownValue: props.tableAccess?.[props.uniqueCol]?.Requested.dropDown
                                    },
                                    'Generated': {
                                        textColor: '#168444',
                                        backgroundColor: '#EBFDFF',
                                        isDropDown: false,
                                    },
                                },
                            };

                            const content = customData[headersEl]?.[temp];

                            if (content === undefined) return null;

                            const open = Boolean(anchorEl);
                            const id = open ? 'simple-popover' : undefined;

                            const handleClickPopOver = () => {
                                setAnchorEl(divRef.current);
                            };

                            const handleClose = () => {
                                setAnchorEl(null);
                            };

                            return (
                                <td
                                    key={index}
                                    align={props.columnData.find(el => el.column === headersEl)?.textAlign}
                                    style={{
                                        padding: '0px 10px',
                                        whiteSpace: "nowrap",
                                        verticalAlign: 'middle',
                                    }}
                                >
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <div
                                            ref={divRef}
                                            style={{
                                                display: 'flex',
                                                padding: '2px 6px',
                                                alignItems: 'center',
                                                gap: '8px',
                                                borderRadius: '4px',
                                                background: content.backgroundColor,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    overflow: 'hidden',
                                                    color: content.textColor,
                                                    textOverflow: 'ellipsis',
                                                    fontFamily: 'Switzer',
                                                    fontSize: '8px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    lineHeight: '150%',
                                                    letterSpacing: '0.32px',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>

                                        <IconButton
                                            sx={{ marginLeft: '4px' }}
                                            onClick={
                                                (event) => {
                                                    event.stopPropagation();
                                                    handleClickPopOver();
                                                }
                                            }
                                            hidden={!content.isDropDown}
                                            size='small'
                                        >
                                            <Icon
                                                iconName='arrow-down'
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#00286D',
                                                    sx: {
                                                        height: '8px',
                                                        width: '8px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            sx={{
                                                flexDirection: 'column',
                                                borderRadius: '4px',
                                                marginTop: '4px',
                                                boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                                            }}
                                        >
                                            {
                                                content.dropDownValue?.map((el, index) => (
                                                    <Typography
                                                        key={index}
                                                        sx={{
                                                            color: el.color,
                                                            fontFamily: 'Switzer',
                                                            fontSize: '8px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '150%',
                                                            letterSpacing: '0.32px',
                                                            padding: '8px 16px',
                                                            cursor: 'pointer',
                                                            borderBottom: index + 1 !== content.length ? '1px solid #E9E9EA' : 'none',
                                                            transition: 'color 0.2s ease-in-out',
                                                            '&:hover': {
                                                                color: el.hoverColor,
                                                            },
                                                        }}
                                                        hidden={el.hidden}
                                                        onClick={() => props.customAction(el.value, props.uniqueCol)}
                                                    >
                                                        {el.label}
                                                    </Typography>
                                                ))
                                            }
                                        </Popover>
                                    </Stack>
                                </td>
                            );
                        }
                    })
                }
            </tr >

            {
                openChild ?
                    <tr className='row-hover-disabled'>
                        <td td colSpan="100%" style={{ borderTopWidth: '1.75px', borderBottomWidth: '1.75px' }}>
                            <Grid style={{ 'width': customScreenWidth + 'px', 'borderRadius': '5px', marginLeft: '4.25px' }}>
                                <div className='mt-2'>
                                    <IconButton
                                        aria-label="expand row"
                                        size="large"
                                        onClick={() => setOpenGL(!openGL)}
                                        disableFocusRipple={true}
                                        disableRipple={true}
                                        className='mt-1'
                                    >
                                        {
                                            openGL ?
                                                <Stack direction='row' spacing={1} justifyContent="center" alignItems="center">
                                                    <Icon
                                                        iconName={'arrow-up'}
                                                        svgIconProps={{
                                                            sx: {
                                                                width: '12px',
                                                                height: '12px',
                                                            }
                                                        }}
                                                    />
                                                    <p style={{ 'color': 'black', 'fontSize': '1.2rem', 'fontWeight': '425' }}>General Ledger - {transactionNo}</p>
                                                </Stack>
                                                :
                                                <Stack direction='row' spacing={1} justifyContent="center" alignItems="center">
                                                    <Icon
                                                        iconName={'arrow-down'}
                                                        svgIconProps={{
                                                            sx: {
                                                                width: '12px',
                                                                height: '12px',
                                                            }
                                                        }}
                                                    />
                                                    <p style={{ 'color': 'black', 'fontSize': '1.2rem', 'fontWeight': '425' }}>General Ledger - {transactionNo}</p>
                                                </Stack>
                                        }
                                    </IconButton>

                                    <Collapse in={openGL} timeout="auto" unmountOnExit style={{ transitionDuration: '0ms !important' }}>
                                        <Grid item xs={12} style={{ 'borderRadius': '5px' }}>
                                            <div className='rounded-10 table-responsive' style={{ 'maxHeight': '350px', 'marginTop': '-10px', marginLeft: '30px' }}>
                                                <div className='mt-4'>
                                                    <TableNoFilter
                                                        uniqueKey={'id'}
                                                        dataTable={dataTableGL}
                                                        rowsCount={rowsCountGL}
                                                        setNumPage={1}
                                                        setRowsCount={setRowsCountGL}
                                                        getData={null}
                                                        columnData={columnDataGL}
                                                        dataMap={dataMapGL}
                                                        SelectedData={SelectedDataGL}
                                                        setSelectedData={setSelectedDataGL}
                                                        sortArr={sortArrGL}
                                                        setSortArr={setSortArrGL}
                                                        setGoTo={1}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Collapse>
                                </div>

                                {/* <hr
                                    className='mt-3 mb-3'
                                    style={{
                                        marginLeft: '30px',
                                        borderWidth: '1.75px',
                                        color: 'inherit !important',
                                        marginTop: '0px !important',
                                        marginRight: '0px !important',
                                        marginBottom: '0px !important',
                                        borderColor: 'inherit !important',
                                        backgroundColor: 'inherit !important',
                                    }}
                                /> */}

                                {/* <div className='mb-3 mt-3'>
                                    <IconButton
                                        aria-label="expand row"
                                        size="large"
                                        onClick={() => setOpenCB(!openCB)}
                                        disableFocusRipple={true}
                                        disableRipple={true}
                                    >
                                        {
                                            openCB ?
                                                <Stack direction='row' spacing={1} justifyContent="center" alignItems="center">
                                                    <Icon
                                                        iconName={'arrow-up'}
                                                        svgIconProps={{
                                                            sx: {
                                                                width: '12px',
                                                                height: '12px',
                                                            }
                                                        }}
                                                    />
                                                    <p style={{ 'color': 'black', 'fontSize': '1.2rem', 'fontWeight': '425' }}>Balance - {transactionNo}</p>
                                                </Stack>
                                                :
                                                <Stack direction='row' spacing={1} justifyContent="center" alignItems="center">
                                                    <Icon
                                                        iconName={'arrow-down'}
                                                        svgIconProps={{
                                                            sx: {
                                                                width: '12px',
                                                                height: '12px',
                                                            }
                                                        }}
                                                    />
                                                    <p style={{ 'color': 'black', 'fontSize': '1.2rem', 'fontWeight': '425' }}>Balance - {transactionNo}</p>
                                                </Stack>
                                        }
                                    </IconButton>

                                    <Collapse in={openCB} timeout="auto" unmountOnExit>
                                        <div className="rounded-10 mt-2 mb-1" style={{ maxHeight: maxHeight, marginLeft: '23.5px' }}>
                                            <div className="mt-2">
                                                <TableBalance Data={DataCB} isLoadingBackDrop={null} maxHeight={maxHeight} />
                                            </div>
                                        </div>
                                    </Collapse>
                                </div> */}
                            </Grid>
                        </td >
                    </tr >
                    :
                    <></>
            }
        </>
    )
}

export default BodyComponent