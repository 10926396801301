import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Grid, Box, Tabs, Tab, Typography } from '@mui/material';

import ApiService, { isListAsc } from '../../../services/ApiService';
import TableX from '../../../components/Tables/TableX';
import ButtonCoreTaxSystem from './Buttons/ButtonCoreTaxSystem';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';

import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';
import { handleDataTable } from "../../../utils/reusableFunction.jsx";
import { TabContext, TabPanel } from '@mui/lab';
import ModalCancelRequest from './Modals/ModalCancelRequest.jsx';
import ModalRequest from './Modals/ModalRequest.jsx';
import ModalImportTaxNumber from './Modals/ModalImportTaxNumber.jsx';
import { updateDateFormat } from '../../../utils/date.jsx';
import SelectDataInvoice from './Selects/SelectDataInvoice.jsx';
import { newWindowConfig } from '../../../utils/masks.jsx';
import ModalPreviewDetail from './Modals/ModalPreviewDetail.jsx';
import ModalConfirmImport from './Modals/ModalConfirmImport.jsx';
import TableEmptyInfo from '../../../components/Components/TableEmptyInfo.jsx';

const CustomTab = (props) => {
    return (
        <Tab
            {...props}
            sx={{
                display: 'flex',
                padding: '8px 16px !important',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                minHeight: '30px',
                height: '30px',
                backgroundColor: 'transparent !important',
                borderRadius: '0px',
                borderBottom: '0px solid transparent !important',
                textTransform: 'none',
            }}
            label={
                <Typography
                    sx={{
                        color: props.isSelected ? '#00286D' : 'var(--Mjr-blue-2, #747475)',
                        textAlign: 'center',
                        fontFamily: 'Switzer',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: props.isSelected ? '700' : '400',
                    }}
                >
                    {props.label}
                </Typography>
            }
        />
    );
};

const CoreTaxSystemPage = forwardRef((props, ref) => {
    const btnPageRef = useRef(null);
    const btnTableRef = useRef(null);
    const tabTableRef = useRef(null);
    const selectDataRef = useRef(null);

    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const param = 'invoice/invoice';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(isListAsc === true ? 0 : 1);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [tableAccess, setTableAccess] = useState([]);

    const [tabValue, setTabValue] = useState(0);
    const [totalInv, setTotalInv] = useState(0);
    const [totalRequest, setTotalRequest] = useState(0);
    const [totalImport, setTotalImport] = useState(0);

    const [isModalCancel, setIsModalCancel] = useState(false);
    const [isModalImport, setIsModalImport] = useState(false);

    const [isModalRequest, setIsModalRequest] = useState(false);
    const [isRequest, setIsRequest] = useState(false);
    const [RadioValue, setRadioValue] = useState(null);
    const [Data1, setData1] = useState('');
    const [Data2, setData2] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filter1, setFilter1] = useState('');
    const [filter2, setFilter2] = useState('');

    const [isModalInvoice, setIsModalInvoice] = useState(false);
    const [subTitleInvoice, setSubTitleInvoice] = useState('');
    const [isLoadingI, setIsLoadingI] = useState(false);
    const [dataI, setDataI] = useState([]);
    const [SelectedDataI, setSelectedDataI] = useState({});
    const [dataCheckI, setDataCheckI] = useState([]);
    const [totalRowsI, setTotalRowsI] = useState(50);
    const [dataMapI, setDataMapI] = useState([]);
    const [dataTableI, setDataTableI] = useState([]);
    const [columnDataI, setColumnDataI] = useState([]);

    const [isModalPreview, setIsModalPreview] = useState(false);
    const [titlePreview, setTitlePreview] = useState('');
    const [subTitlePreview, setSubTitlePreview] = useState('');
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [dataP, setDataP] = useState([]);
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);
    const [dataMapP, setDataMapP] = useState([]);
    const [dataTableP, setDataTableP] = useState([]);
    const [columnDataP, setColumnDataP] = useState([]);

    const [isModalConfirmImport, setIsModalConfirmImport] = useState(false);
    const [subTitleConfirmImport, setSubTitleConfirmImport] = useState('');
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [dataImport, setDataImport] = useState(null);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Invoice', link: '/Majura/Invoice', style: '' },
        { label: 'Tax Invoice Number Generation', link: '/Majura/TaxNumberGeneration', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Tax Invoice Number Generation - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        let filterBy = filterName.includes('GENERAL INVOICE') ? '3' : filterName.includes('INVOICE') ? '2' : '1';

        let params = [
            {
                "attr": "filterBy",
                "value": filterBy
            },
            {
                "attr": "startDate",
                "value": (filterName.includes('INVOICE') === false && filterName.includes('GENERAL INVOICE') === false) ? filter1 : ''
            },
            {
                "attr": "endDate",
                "value": (filterName.includes('INVOICE') === false && filterName.includes('GENERAL INVOICE') === false) ? filter2 : ''
            },
            {
                "attr": "startInvoiceNo",
                "value": (filterName.includes('INVOICE') || filterName.includes('GENERAL INVOICE')) ? filter1 : '0'
            },
            {
                "attr": "endInvoiceNo",
                "value": (filterName.includes('INVOICE') || filterName.includes('GENERAL INVOICE')) ? filter2 : '0'
            }
        ];

        try {
            const [invRes, requestRes, importRes] = await Promise.all([
                ApiService.OperationCustomPostByPage(param + '/PostByPageEFaktur?', 'PAGE', 1, 1, params, [], []),
                ApiService.OperationCustomPostByPage(param + '/PostByPageExpEFakturHis?', 'PAGE', 1, 1, params, [], []),
                ApiService.OperationCustomPostByPage(param + '/PostByPageImpEFakturHis?', 'PAGE', 1, 1, params, [], []),
            ]);

            if (invRes.data.code === 200) {
                setTotalInv(invRes.data.totalRowCount);
            }

            if (requestRes.data.code === 200) {
                setTotalRequest(requestRes.data.totalRowCount);
            }

            if (importRes.data.code === 200) {
                setTotalImport(importRes.data.totalRowCount);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getDateNow = () => {
        const dateNow = new Date();
        const firstDayOfMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

        setFilter1(updateDateFormat(firstDayOfMonth));
        setFilter2(updateDateFormat(dateNow));

        setFilterName('FILTER BY: ' + updateDateFormat(firstDayOfMonth) + ' ~ ' + updateDateFormat(dateNow));
    }

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        let filterBy = filterName.includes('GENERAL INVOICE') ? '3' : filterName.includes('INVOICE') ? '2' : '1';
        let additionalParam = tabValue === 0 ? 'PostByPageEFaktur?' : tabValue === 1 ? 'PostByPageExpEFakturHis?' : 'PostByPageImpEFakturHis?';

        let params = [
            {
                "attr": "filterBy",
                "value": filterBy
            },
            {
                "attr": "startDate",
                "value": (filterName.includes('INVOICE') === false && filterName.includes('GENERAL INVOICE') === false) ? filter1 : ''
            },
            {
                "attr": "endDate",
                "value": (filterName.includes('INVOICE') === false && filterName.includes('GENERAL INVOICE') === false) ? filter2 : ''
            },
            {
                "attr": "startInvoiceNo",
                "value": (filterName.includes('INVOICE') || filterName.includes('GENERAL INVOICE')) ? filter1 : '0'
            },
            {
                "attr": "endInvoiceNo",
                "value": (filterName.includes('INVOICE') || filterName.includes('GENERAL INVOICE')) ? filter2 : '0'
            }
        ];

        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        ApiService.OperationCustomPostByPage(param + '/' + additionalParam, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    const dataMapping = {
                        0: response.data.data.invoiceExportFakturs,
                        1: response.data.data.invoiceExpFakHis,
                        2: response.data.data.invoiceImpFakHis,
                    };

                    let data = dataMapping[tabValue] || [];

                    setSelectedData({})

                    let temp = data
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, tabValue === 0 ? 'invoiceId' : 'id');
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FINVTNG')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        setTableAccess(
                            {
                                TaxNumberGeneration: {
                                    Requested: {
                                        access: false,
                                        dropDown: [
                                            {
                                                value: 'cancel',
                                                label: 'Cancel Request',
                                                color: '#BE2957',
                                                hoverColor: '#E5E9F0',
                                                hidden: response.data.data.userAccess.find((elm) => elm.accessCode === 'UPP') ? false : true
                                            },
                                        ]
                                    }
                                }
                            }
                        );


                        if (hasAccess) {
                            getDateNow();
                        }
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getDataInvoice = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingI(true);
        let params = [
            {
                "attr": "filterBy",
                "value": RadioValue
            },
            {
                "attr": "startDate",
                "value": RadioValue === '1' ? Data1 : ''
            },
            {
                "attr": "endDate",
                "value": RadioValue === '1' ? Data2 : ''
            },
            {
                "attr": "startInvoiceNo",
                "value": RadioValue !== '1' ? Data1 : '0'
            },
            {
                "attr": "endInvoiceNo",
                "value": RadioValue !== '1' ? Data2 : '0'
            },
            {
                "attr": "isExported",
                "value": "false"
            }
        ];
        ApiService.OperationCustomPostByPage(param + '/PostByPageEFaktur?', 'SEAPREV', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataI({})

                    let allData = response.data.data.invoiceExportFakturs;

                    setDataI(allData);
                    setDataCheckI(allData);

                    let temp = allData
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapI(indexed)

                    const startIndex = (pageNumber - 1) * 50;
                    const paginatedData = allData.slice(startIndex, startIndex + 50);
                    handleDataTable(paginatedData, response.data.data.columns.headerColumns, setDataTableI, 'invoiceId');
                    setColumnDataI(response.data.data.columns.headerColumns)
                    setTotalRowsI(response.data.totalRowCount)

                    setIsLoadingI(false)
                }
                setIsLoadingI(false)
            })
            .catch(function (error) {
                setIsLoadingI(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const handleManageInvoice = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingI(true);

        const filteredData = filters.length > 0
            ? dataMapI.filter(item =>
                filters.every(filter =>
                    item[filter.field]?.toString().toLowerCase().includes(filter.data.toLowerCase())
                )
            )
            : dataMapI;

        const sortedData = orderBy.length > 0
            ? [...filteredData].sort((a, b) => {
                for (let sorter of orderBy) {
                    const { attr, method } = sorter;
                    const valA = a[attr]?.toString().toLowerCase() || "";
                    const valB = b[attr]?.toString().toLowerCase() || "";

                    if (valA < valB) return method === "asc" ? -1 : 1;
                    if (valA > valB) return method === "asc" ? 1 : -1;
                }
                return 0;
            })
            : filteredData;

        const startIndex = (pageNumber - 1) * pageSize;
        const paginatedData = sortedData.slice(startIndex, startIndex + pageSize);

        handleDataTable(paginatedData, columnDataI, setDataTableI, "invoiceId");

        setTimeout(() => {
            setIsLoadingI(false);
        }, 1000);
    };

    const emptyState = () => {
        setNumPage(isListAsc === true ? 0 : 1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setGoTo(1);
        setFilterBy([]);
        setStateX({})
        setSortArr([])
    };

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data Success!' });

        emptyState();
        getData(isListAsc === true ? 0 : 1, 50, [])
    }

    const handleReset = () => {
        emptyState();
        getData(isListAsc === true ? 0 : 1, 50, [])
    }

    function CellDoubleClick(row) {
        if (tabValue === 0) {
            window.open('/Majura/Invoice/' + SelectedData.jobTypeId + '/' + SelectedData.invoiceId, newWindowConfig);
        }
        else {
            handleClickPreview();
        }
    }

    const getContainerHeight = () => {
        const hTitle = 80
        const hHeader = 108
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hTabPage = (tabTableRef?.current?.clientHeight || 14) + 12
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hTabPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(isListAsc === true ? 0 : 1);
        setRowsCount(value);
        getData(isListAsc === true ? 0 : 1, value, filter, sortArr);
    }

    const handleChangeTab = (event, newValue) => {
        emptyState();
        setTabValue(newValue);
        setIsLoading(true);
    };

    const handleClickCancel = () => {
        if (isModalCancel) {
            setIsModalCancel(false);
        }
        else {
            setIsModalCancel(true);
        }
    }

    const handleCancelRequest = () => {
        setIsLoadingBackDrop(true);

        let body = {
            "invoice": {
                "id": '' + SelectedData.invoiceId,
                "isNonJob": SelectedData.isNonJob,
            }
        };
        ApiService.OperationCustomPost(param + '/UnApproveEFaktur', body)
            .then((response) => {
                if (response.data.code === 200) {
                    showToast({ type: 'success', message: 'Cancel Request Success!' });
                    getData(1, 50, []);
                    handleClickCancel();
                }
                setIsLoadingBackDrop(false)
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const handleClickRequest = () => {
        if (isModalRequest) {
            setIsModalRequest(false);
        }
        else {
            setIsRequest(true);
            setRadioValue(null);
            setData1('');
            setData2('');
            setIsModalRequest(true);
        }
    }

    const handleClickImport = () => {
        if (isModalImport) {
            setIsModalImport(false);
        }
        else {
            setIsModalImport(true);
        }
    }

    const handleImportData = async (fileObject, type = 'normal') => {
        if (type === 'normal') {
            setIsLoadingBackDrop(true);
        } else {
            setIsLoadingC(true);
        }

        try {
            const response = await fetch(fileObject.url);
            const blob = await response.blob();

            const file = new File([blob], fileObject.name, { type: blob.type });

            const formData = new FormData();
            formData.append("fakturCoretax", file);

            const uploadResponse = await ApiService.OperationUploadFile(param + '/PreviewImportEFaktur?pageCode=PAGE&pageNumber=1&pageSize=1', formData)

            if (uploadResponse.data.code === 200) {
                setDataImport(fileObject);
                setIsLoadingBackDrop(false);
                setSubTitleConfirmImport(fileObject.name);
                setSelectedDataC({});
                let allData = uploadResponse.data.data.invoiceImpFakHisDetails;

                setDataC(allData);

                let temp = allData
                let indexed = temp.map((el, index) => {
                    let indexedTemp = {
                        ...el,
                        index
                    }
                    return indexedTemp
                })

                setDataMapC(indexed);
                handleDataTable(indexed, uploadResponse.data.data.columns.headerColumns, setDataTableC, 'invoiceId');
                setColumnDataC(uploadResponse.data.data.columns.headerColumns);
                setTotalRowsC(uploadResponse.data.data.invoiceImpFakHisDetails.length);

                if (type === 'normal') {
                    handleClickConfirmImport();
                    handleClickImport();
                }

                if (type === 'continue') {
                    setIsLoadingC(false);
                }
            }
            else {
                if (type === 'continue') {
                    setIsLoadingC(false);
                }
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: `Import File Failed, ${uploadResponse.data.error}!` });
            }
        }
        catch (error) {
            if (type === 'continue') {
                setIsLoadingC(false);
            }
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: `Import File Failed, ${error.toString()}!` });
        }
    }

    const handleFilterPreview = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingC(true);
        const filteredData = filters.length > 0
            ? dataMapC.filter(item =>
                filters.every(filter =>
                    item[filter.field]?.toString().toLowerCase().includes(filter.data.toLowerCase())
                )
            )
            : dataMapC;

        const sortedData = orderBy.length > 0
            ? [...filteredData].sort((a, b) => {
                for (let sorter of orderBy) {
                    const { attr, method } = sorter;
                    const valA = a[attr]?.toString().toLowerCase() || "";
                    const valB = b[attr]?.toString().toLowerCase() || "";

                    if (valA < valB) return method === "asc" ? -1 : 1;
                    if (valA > valB) return method === "asc" ? 1 : -1;
                }
                return 0;
            })
            : filteredData;

        handleDataTable(sortedData, columnDataC, setDataTableC, "invoiceId");
        setIsLoadingC(false);
    };

    const handleClickConfirmImport = () => {
        if (isModalConfirmImport) {
            setIsModalConfirmImport(false);
        }
        else {
            setIsModalConfirmImport(true);
        }
    }

    const handleDropDownAction = (selectData, actionType, pageCode) => {
        setSelectedData(selectData);
        if (pageCode === 'TaxNumberGeneration') {
            switch (actionType) {
                case 'cancel':
                    handleClickCancel();
                    break;
                default:
                    break;
            }
        }
    }

    const handleClickFilter = () => {
        setIsRequest(false);

        if (!filterName.includes('INVOICE') && !filterName.includes('GENERAL INVOICE')) {
            setRadioValue('1');
        }
        else if (filterName.includes('INVOICE')) {
            setRadioValue('2');
        }
        else if (filterName.includes('GENERAL INVOICE')) {
            setRadioValue('3');
        }

        setData1(filter1);
        setData2(filter2);
        setIsModalRequest(true);
    }

    const handleFilterData = (value, data1, data2) => {
        if (data1 !== filter1 || data2 !== filter2) {
            if (value === '1') {
                setFilterName('FILTER BY: ' + data1 + ' ~ ' + data2);
            }
            else if (value === '2') {
                setFilterName('FILTER BY: INVOICE ' + data1 + ' ~ ' + data2);
            }
            else if (value === '3') {
                setFilterName('FILTER BY: GENERAL INVOICE ' + data1 + ' ~ ' + data2);
            }

            setFilter1(data1);
            setFilter2(data2);
            handleClickRequest();
            setIsLoading(true);
        }
        else {
            handleClickRequest();
            getData(1, 50, []);
        }
    }

    const handleClickInvoice = (type = 'normal') => {
        if (isModalInvoice && type === 'normal') {
            setIsModalInvoice(false);
        }
        else {
            let title = '';

            if (RadioValue === '1') {
                let date1 = new Date(Data1);
                date1 = date1.toLocaleDateString("id-ID", { day: "2-digit", month: "short", year: "numeric" });

                let date2 = new Date(Data2);
                date2 = date2.toLocaleDateString("id-ID", { day: "2-digit", month: "short", year: "numeric" });

                title = 'Print Date ' + date1 + ' ~ ' + date2;
            }
            else if (RadioValue === '2') {
                title = 'Invoice ' + Data1 + ' ~ ' + Data2;
            }
            else {
                title = 'General Invoice ' + Data1 + ' ~ ' + Data2;
            }
            setSubTitleInvoice(title);
            setIsModalInvoice(true);
            getDataInvoice(1, 1000000, []);
            handleClickRequest();

            if (type === 'continue') {
                if (selectDataRef.current) {
                    selectDataRef.current.emptyState();
                }
            }
        }
    }

    const handleClickPreview = () => {
        if (isModalPreview) {
            setIsModalPreview(false);
        }
        else {
            setIsModalPreview(true);
            setTitlePreview(tabValue === 1 ? 'Batch Request - Tax Invoice Number' : 'Tax Invoice Number Preview');
            setSubTitlePreview(tabValue === 1 ? SelectedData.batchName : SelectedData.importId);
            getDataPreview(1, 50, []);
        }
    }

    const getDataPreview = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingP(true);

        let params = [];
        let paramExtension = tabValue === 1 ? '/PostByPageExpEFakturHisDetail?' : '/PostByPageImpEFakturHisDetail?';
        params = [
            {
                'attr': tabValue === 1 ? 'InvExpFakHisId' : 'invImpFakHisId',
                value: '' + SelectedData.id
            }
        ];
        ApiService.OperationCustomPostByPage(param + paramExtension, tabValue === 1 ? 'SEARCH' : 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataP({})

                    let allData = tabValue === 1 ? response.data.data.invoiceExpFakHisDetails : response.data.data.invoiceImpFakHisDetails;

                    setDataP(allData);

                    let temp = allData
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapP(indexed)
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableP, 'invoiceId');
                    setColumnDataP(response.data.data.columns.headerColumns)
                    setTotalRowsP(response.data.totalRowCount)

                    setIsLoadingP(false)
                }
                setIsLoadingP(false)
            })
            .catch(function (error) {
                setIsLoadingP(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    useEffect(() => {
        getData(1, 50, []);

        fetchData();

        const interval = setInterval(fetchData, 5 * 60 * 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter1, filter2, filterName, tabValue]);

    const ButtonComponents = () => {
        return (
            <ButtonCoreTaxSystem
                ReloadData={ReloadData}
                UploadData={handleClickRequest}
                ImportData={handleClickImport}
                DataRegUserAccess={DataRegUserAccess}
                filterName={filterName}
                handleClickFilter={handleClickFilter}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '16px 32px 24px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Tax Invoice Number Generation'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <TabContext value={tabValue}>
                        <div ref={tabTableRef} style={{ marginTop: '12px' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChangeTab}
                                    sx={{ minHeight: '30px', height: '30px' }}
                                    TabIndicatorProps={{
                                        sx: {
                                            backgroundColor: '#00286D',
                                            height: '2px',
                                        },
                                    }}
                                >
                                    <CustomTab label={`Invoice List (${totalInv})`} value={0} isSelected={tabValue === 0 ? true : false} />
                                    <CustomTab label={`Request History (${totalRequest})`} value={1} isSelected={tabValue === 1 ? true : false} />
                                    <CustomTab label={`Import History (${totalImport})`} value={2} isSelected={tabValue === 2 ? true : false} />
                                </Tabs>
                            </Box>
                        </div>

                        <div ref={btnTableRef}>
                            {
                                ButtonComponent.RefreshButton(
                                    sortArr,
                                    rowsCount,
                                    columnData,
                                    setGoTo,
                                    handleReset,
                                    setFilterBy,
                                    filterBy,
                                    setFilter,
                                    setNumPage,
                                    setRowsCount,
                                    getData,
                                    stateX,
                                    setStateX
                                )
                            }
                        </div>

                        {
                            dataTable.length > 0 && (
                                <div
                                    style={{
                                        marginTop: '16px',
                                        padding: '2px 0px 16px 16px',
                                        borderRadius: '12px',
                                        border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                                        background: 'var(--Mjr-white-4, #FFF)',
                                        boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                                    }}
                                >
                                    <div
                                        className="rounded-10 table-responsive"
                                        style={{
                                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                                        }}
                                    >
                                        {
                                            tabValue === 0 && (
                                                <TabPanel value={0} className='p-0'>
                                                    {
                                                        isLoading ?
                                                            <LoadingSpinner />
                                                            :
                                                            <TableX
                                                                uniqueKey={'invoiceId'}
                                                                goTo={goTo}
                                                                rowsCount={rowsCount}
                                                                setNumPage={setNumPage}
                                                                setRowsCount={setRowsCount}
                                                                getData={getData}
                                                                dataTable={dataTable}
                                                                columnData={columnData}
                                                                dataMap={dataMap}
                                                                SelectedData={SelectedData}
                                                                setSelectedData={setSelectedData}
                                                                setFilter={setFilter}
                                                                setFilterBy={setFilterBy}
                                                                CellDoubleClick={CellDoubleClick}
                                                                stateX={stateX}
                                                                setStateX={setStateX}
                                                                sortArr={sortArr}
                                                                setSortArr={setSortArr}
                                                                setGoTo={setGoTo}
                                                                uniqueCol={'TaxNumberGeneration'}
                                                                customAction={handleDropDownAction}
                                                                tableAccess={tableAccess}
                                                            />
                                                    }
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            tabValue === 1 && (
                                                <TabPanel value={1} className='p-0'>
                                                    {
                                                        isLoading ?
                                                            <LoadingSpinner />
                                                            :
                                                            <TableX
                                                                uniqueKey={'id'}
                                                                goTo={goTo}
                                                                rowsCount={rowsCount}
                                                                setNumPage={setNumPage}
                                                                setRowsCount={setRowsCount}
                                                                getData={getData}
                                                                dataTable={dataTable}
                                                                columnData={columnData}
                                                                dataMap={dataMap}
                                                                SelectedData={SelectedData}
                                                                setSelectedData={setSelectedData}
                                                                setFilter={setFilter}
                                                                setFilterBy={setFilterBy}
                                                                CellDoubleClick={CellDoubleClick}
                                                                stateX={stateX}
                                                                setStateX={setStateX}
                                                                sortArr={sortArr}
                                                                setSortArr={setSortArr}
                                                                setGoTo={setGoTo}
                                                                uniqueCol={'TaxNumberGeneration-Request'}
                                                            />
                                                    }
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            tabValue === 2 && (
                                                <TabPanel value={2} className='p-0'>
                                                    {
                                                        isLoading ?
                                                            <LoadingSpinner />
                                                            :
                                                            <TableX
                                                                uniqueKey={'id'}
                                                                goTo={goTo}
                                                                rowsCount={rowsCount}
                                                                setNumPage={setNumPage}
                                                                setRowsCount={setRowsCount}
                                                                getData={getData}
                                                                dataTable={dataTable}
                                                                columnData={columnData}
                                                                dataMap={dataMap}
                                                                SelectedData={SelectedData}
                                                                setSelectedData={setSelectedData}
                                                                setFilter={setFilter}
                                                                setFilterBy={setFilterBy}
                                                                CellDoubleClick={CellDoubleClick}
                                                                stateX={stateX}
                                                                setStateX={setStateX}
                                                                sortArr={sortArr}
                                                                setSortArr={setSortArr}
                                                                setGoTo={setGoTo}
                                                                uniqueCol={'TaxNumberGeneration-Import'}
                                                            />
                                                    }
                                                </TabPanel>
                                            )
                                        }
                                    </div>

                                    <div style={{ paddingRight: '16px' }}>
                                        <Box sx={{ borderTop: 1, borderColor: 'divider' }} />
                                        <div style={{ marginTop: '8px' }}>
                                            <Paginate
                                                page={numPage}
                                                limit={rowsCount}
                                                totalData={totalRows}
                                                onChange={onChangePaging}
                                                onChangeLimit={onChangeLimit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <TableEmptyInfo
                            data={dataTable}
                            getContainerHeight={getContainerHeight}
                            isLoading={isLoading}
                        />
                    </TabContext>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 10000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalRequest && (
                    <ModalRequest
                        isModal={isModalRequest}
                        handleClick={handleClickRequest}
                        handleRequest={() => handleClickInvoice('continue')}
                        isRequest={isRequest}
                        setIsRequest={setIsRequest}
                        RadioValue={RadioValue}
                        setRadioValue={setRadioValue}
                        Data1={Data1}
                        setData1={setData1}
                        Data2={Data2}
                        setData2={setData2}
                        handleFilterData={handleFilterData}
                    />
                )
            }

            {
                isModalImport && (
                    <ModalImportTaxNumber
                        isModal={isModalImport}
                        handleClick={handleClickImport}
                        handleImportData={handleImportData}
                    />
                )
            }

            {
                isModalCancel && (
                    <ModalCancelRequest
                        isModal={isModalCancel}
                        handleClick={handleClickCancel}
                        handleCancelRequest={handleCancelRequest}
                    />
                )
            }

            {
                isModalInvoice && (
                    <SelectDataInvoice
                        ref={selectDataRef}
                        setIsLoadingBackDrop={setIsLoadingBackDrop}
                        isModal={isModalInvoice}
                        subTitle={subTitleInvoice}
                        handleClick={handleClickInvoice}
                        handleChangeData={handleClickRequest}

                        getData={handleManageInvoice}
                        isLoading={isLoadingI}
                        totalRows={totalRowsI}
                        setTotalRows={setTotalRowsI}
                        SelectedData={SelectedDataI}
                        setSelectedData={setSelectedDataI}
                        data={dataI}
                        columnData={columnDataI}
                        dataMap={dataMapI}
                        dataTable={dataTableI}
                        dataCheck={dataCheckI}
                        setDataCheck={setDataCheckI}

                        filterByData={RadioValue}
                        Data1={Data1}
                        Data2={Data2}
                        emptyStateParent={emptyState}
                        getDataParent={getData}
                        fetchDataTab={fetchData}
                    />
                )
            }

            {
                isModalPreview && (
                    <ModalPreviewDetail
                        isModal={isModalPreview}
                        title={titlePreview}
                        subTitle={subTitlePreview}
                        handleClick={handleClickPreview}

                        getData={getDataPreview}
                        isLoading={isLoadingP}
                        setIsLoading={setIsLoadingP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}
                        data={dataP}
                        columnData={columnDataP}
                        dataMap={dataMapP}
                        dataTable={dataTableP}
                        tableAccess={tableAccess}
                    />
                )
            }

            {
                isModalConfirmImport && (
                    <ModalConfirmImport
                        isModal={isModalConfirmImport}
                        handleClick={handleClickConfirmImport}
                        subTitle={subTitleConfirmImport}
                        setTotalRows={setTotalRowsC}
                        getData={handleFilterPreview}
                        columnData={columnDataC}
                        isLoading={isLoadingC}
                        dataMap={dataMapC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        dataTable={dataTableC}
                        totalRows={totalRowsC}
                        data={dataC}
                        handleImportData={handleImportData}

                        emptyStateParent={emptyState}
                        getDataParent={getData}
                        fetchDataTab={fetchData}
                        dataImport={dataImport}
                        setIsLoadingBackDrop={setIsLoadingBackDrop}
                    />
                )
            }
        </Box>
    )
})

export default CoreTaxSystemPage;