import {
    IconButton,
    Popover,
    Stack, Typography,
    // IconButton, InputAdornment 
} from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { CustomDatePicker, Icon, Input, Selection, TickBox } from '../ui';
import { defaultDate } from '../../utils/date';
import { isInfossNoList } from '../../services/ApiService';
import noDataAvailable from '../../Assets/Background/noDataAvailable.png';

const TableCheck = (props) => {
    const [filterJson, setFilterJson] = useState({})
    const [filterArr, setFilterArr] = useState([])
    const [isAll, setIsAll] = useState(false)

    useEffect(() => {
        handleCheckIsAll(props.dataCheck);
        // eslint-disable-next-line
    }, [props.dataCheck])

    const filterTable = (key, val, type = 'Standard') => {
        if (type !== 'Stop') {
            let filter = filterJson
            let temp = {
                field: key,
                data: val
            }
            let arr = []

            if (!filter[key]) {
                filter[key] = temp
                setFilterJson(filter)
            } else {
                filter[key].data = val
                setFilterJson(filter)
            }

            if (filter[key].data.length === 0) {
                delete filter[key]
                setFilterJson(filter)
            }

            for (const key in filter) {
                arr.push(filter[key])
            }

            setFilterArr(arr)
            props.setStateX({ ...props.stateX, [key]: val })

            if (type !== 'Stop') {
                props.setNumPage(1)
                props.setRowsCount(50)
                props.setGoTo(1)
                let arr = []
                let arrFilter = []
                let filter = { ...props.stateX, [key]: val }
                for (const [key, value] of Object.entries(filter)) {
                    let realCol = key;
                    let realValue = value;

                    if (realValue === 'true') {
                        realValue = 'Yes';
                    }
                    else if (realValue === 'false') {
                        realValue = 'No';
                    }
                    else if (realValue === 'ACT') {
                        realValue = 'Active';
                    }
                    else if (realValue === 'DEL') {
                        realValue = 'Deleted';
                    }

                    realCol = props.columnData.find(el => el.column === key);

                    arr.push(realCol.text + ': ' + realValue)

                    arrFilter = [
                        ...arrFilter,
                        {
                            field: key,
                            data: value?.trim()
                        }
                    ]
                }
                props.setFilterBy(arr)
                props.setFilter(arrFilter)
                if (props.id !== undefined) {
                    props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
                }
                else {
                    props.getData(1, props.rowsCount, arrFilter, props.sortArr)
                }
            }
        }
    }

    const handleDoubleClick = (el) => {
        props.CellDoubleClick(el)
    }

    function handleKeyDown(e) {
        // console.log(e.keyCode)
        let currIndex = 0
        if (e.keyCode === 38 && props.SelectedData.index > 0) {
            //UP ARROW
            currIndex = props.SelectedData.index
            currIndex -= 1
        } else if (e.keyCode === 40 && props.SelectedData.index < props.rowsCount - 1) {
            //DOWN ARROW
            // alert(props.SelectedData.index)
            currIndex = props.SelectedData.index
            currIndex += 1
        } else if (e.keyCode === 13 && filterArr.length >= 0) {
            //PRESS ENTER
            //THEN DO FILTER
            if (filterArr.length > 0) {
                FilterData();
            }
        } else if (e.keyCode === 13 && props.SelectedData.index !== undefined) {
            handleDoubleClick()
        }

        const copyArr = [...props.dataMap]
        const changeSelectedData = (data) => {
            return data.index === currIndex
        }
        const result = copyArr.filter(changeSelectedData)
        props.setSelectedData(...result);
    }

    const FilterData = () => {
        props.setNumPage(1)
        props.setRowsCount(50)
        props.setGoTo(1)
        let arr = []
        let arrFilter = []
        let filter = props.stateX
        for (const [key, value] of Object.entries(filter)) {
            let realCol = key;
            let realValue = value;

            if (realValue === 'true') {
                realValue = 'Yes';
            }
            else if (realValue === 'false') {
                realValue = 'No';
            }
            else if (realValue === 'ACT') {
                realValue = 'Active';
            }
            else if (realValue === 'DEL') {
                realValue = 'Deleted';
            }

            realCol = props.columnData.find(el => el.column === key);

            arr.push(realCol.text + ': ' + realValue)

            arrFilter = [
                ...arrFilter,
                {
                    field: key,
                    data: value?.trim()
                }
            ]
        }
        props.setFilterBy(arr)
        props.setFilter(arrFilter)
        if (props.id !== undefined) {
            props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
        }
        else {
            props.getData(1, props.rowsCount, arrFilter, props.sortArr)
        }
    }

    const handleCheckIsAll = (dataCheck) => {
        const { dataTable, uniqueKey, setIsSelectAll, dataMap } = props;

        if (dataTable.length === 0) return setIsAll(false);
        const isAllRegistered = dataTable.every(tableItem =>
            dataCheck.some(checkItem => checkItem[uniqueKey] === tableItem[uniqueKey])
        );

        if (setIsSelectAll !== undefined) {
            const isSelectedAll = dataMap.every(tableItem =>
                dataCheck.some(checkItem => checkItem[uniqueKey] === tableItem[uniqueKey])
            );

            setIsSelectAll(isSelectedAll);
        }

        setIsAll(isAllRegistered);
    }

    const handleCheckAll = (value) => {
        let check = 0;
        let checkData = [...props.dataCheck];
        let dataNoCheck = props.isCheckedAll ? [...props.dataNoCheck] : [];
        setIsAll(value);

        if (value) {
            props.dataTable.forEach(element => {
                for (let i = 0; i < checkData.length; i++) {
                    if (element[props.uniqueKey] === checkData[i][props.uniqueKey]) {
                        check = 1;
                    }
                }

                if (check === 0) {
                    checkData = [...checkData, props.dataMap.find(data => data[props.uniqueKey] === element[props.uniqueKey])];
                }
                check = 0;
            });

            if (props.isCheckedAll) {
                checkData.forEach(element => {
                    dataNoCheck = dataNoCheck.filter(dt => dt[props.uniqueKey] !== element[props.uniqueKey]);
                });
            }
        }
        else {
            if (props.isCheckedAll) {
                check = 0;
                props.dataTable.forEach(element => {
                    for (let i = 0; i < dataNoCheck.length; i++) {
                        if (element[props.uniqueKey] === dataNoCheck[i][props.uniqueKey]) {
                            check = 1;
                        }
                    }

                    if (check === 0) {
                        dataNoCheck = [...dataNoCheck, props.dataMap.find(data => data[props.uniqueKey] === element[props.uniqueKey])];
                    }
                    check = 0;
                });
            }

            props.dataTable.forEach(element => {
                checkData = checkData.filter(dt => dt[props.uniqueKey] !== element[props.uniqueKey]);
            });
        }

        if (props.calculateTotal !== undefined) {
            props.calculateTotal(checkData)
        }

        props.setDataCheck(checkData);

        if (props.isCheckedAll) {
            props.setDataNoCheck(dataNoCheck);
        }
    }

    const handleCheck = (e, el) => {
        let checkData = [];
        let data = props.dataMap.find(data => data[props.uniqueKey] === el[props.uniqueKey]);
        let dataNoCheck = props.isCheckedAll ? [...props.dataNoCheck] : [];

        if (e.target.checked) {
            checkData = [...props.dataCheck, data];

            if (props.isCheckedAll) {
                dataNoCheck = dataNoCheck.filter(dt => dt[props.uniqueKey] !== data[props.uniqueKey]);
            }
        } else {
            checkData = props.dataCheck.filter(dt => dt[props.uniqueKey] !== data[props.uniqueKey]);

            if (props.isCheckedAll) {
                dataNoCheck = [...props.dataNoCheck, data];
            }
        }

        if (props.calculateTotal !== undefined) {
            props.calculateTotal(checkData);
        }

        props.setDataCheck(checkData);

        if (props.isCheckedAll) {
            props.setDataNoCheck(dataNoCheck);
        }
    };

    const handleCheckOnClick = (el) => {
        let checkData = [];
        let data = props.dataMap.find(data => data[props.uniqueKey] === el[props.uniqueKey]);
        let dataNoCheck = props.isCheckedAll ? [...props.dataNoCheck] : [];

        if (props.dataCheck.filter(dt => dt[props.uniqueKey] === data[props.uniqueKey]).length > 0) {
            checkData = props.dataCheck.filter(dt => dt[props.uniqueKey] !== data[props.uniqueKey])

            if (props.isCheckedAll) {
                dataNoCheck = [...props.dataNoCheck, data];
            }
        } else {
            checkData = [...props.dataCheck, data]

            if (props.isCheckedAll) {
                dataNoCheck = dataNoCheck.filter(dt => dt[props.uniqueKey] !== data[props.uniqueKey]);
            }
        }

        if (props.calculateTotal !== undefined) {
            props.calculateTotal(checkData)
        }

        props.setDataCheck(checkData)

        if (props.isCheckedAll) {
            props.setDataNoCheck(dataNoCheck);
        }
    }

    const SortData = (key) => {
        if (props.sortArr) {
            let temp = {
                attr: key,
                method: 'asc'
            }
            var arr = []
            var cekArr = props.sortArr
            cekArr = cekArr.filter(el => el.attr === key)

            if (cekArr.length > 0) {
                arr = props.sortArr
                arr.forEach(el => {
                    if (el.attr === key) {
                        if (el.method === 'asc') {
                            el.method = 'desc'
                        } else {
                            arr = arr.filter(el => el.attr !== key)
                        }
                    }
                });
            } else {
                arr = [...props.sortArr, temp]
            }
            props.setSortArr(arr)

            let arrFilter = []
            let filter = props.stateX
            for (const [key, value] of Object.entries(filter)) {
                arrFilter = [...arrFilter, {
                    field: key,
                    data: value?.trim()
                }]
            }
            props.getData(1, props.rowsCount, arrFilter, arr)
            props.setNumPage(1)
            props.setGoTo(1)
            // CekSortStatus(key)
        }
    }

    function handleKeyDownInput(e, el) {
        if (e.key === "Enter") {
            filterTable(el.column, e.target.value, 'Standard');
        }
    }

    const CekSortStatus = (key, text) => {
        if (!props.sortArr) return null;

        const sortItem = props.sortArr.find(el => el.attr === key);
        let colors = "#D1D1D2";
        let textColor = "#747475";
        let iconName = 'sort-arrow';

        if (sortItem) {
            if (sortItem.method === "asc") {
                iconName = 'sort-up';
            } else {
                iconName = 'sort-down';
            }
            colors = '#00286D';
            textColor = '#00286D';
        }

        return (
            <Stack
                direction='row'
                spacing='6px'
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: 'fit-content'
                }}
            >
                <Typography
                    sx={{
                        overflow: 'hidden',
                        color: textColor,
                        textOverflow: 'ellipsis',
                        fontFamily: 'Switzer',
                        fontSize: '8px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '150%',
                        letterSpacing: '0.32px',
                    }}
                >
                    {text.toUpperCase()}
                </Typography>

                <Icon
                    iconName={iconName}
                    svgIconProps={{
                        fontSize: 'small',
                        htmlColor: colors,
                        sx: {
                            height: '12px',
                            width: '12px'
                        }
                    }}
                />
            </Stack>
        );
    };

    const TableHeaderRow = React.memo(({ columnData, SortData, CekSortStatus }) => {
        const handleSortClick = useCallback((column) => {
            SortData(column);
        }, [SortData]);

        return (
            <tr style={{ borderBottom: 'transparent' }}>
                {
                    props.columnData.length > 0 ?
                        <th>
                            <TickBox
                                key={0}
                                checked={isAll}
                                onChange={(e) => handleCheckAll(e.target.checked)}
                                onClick={(e) => handleCheckAll(e.target.checked)}
                                baseCheckboxProps={{
                                    sx: {
                                        padding: '0px 0px 0px 8px',
                                        marginTop: '4px'
                                    }
                                }}
                                size={'small'}
                            />
                        </th>
                        :
                        null
                }
                {
                    columnData.map((el, index) => (
                        <th
                            key={index}
                            hidden={el.column === 'noInfoss' && !isInfossNoList ? true : el.hidden}
                            style={{
                                textAlign: el.textAlign,
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal',
                                maxWidth: 'fit-content',
                                cursor: 'pointer',
                                padding: '2px 8px',
                                background: '#FFF',
                                fontSize: '12px !important'
                            }}
                        >
                            <label
                                onClick={() => handleSortClick(el.column)}
                                style={{
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    fontSize: '12px !important',
                                    maxWidth: 'fit-content'
                                }}
                            >
                                {CekSortStatus(el.column, el.text)}
                            </label>
                        </th>
                    ))
                }
            </tr>
        );
    });

    const TableFilterRow = memo(({ columnData, filterTable }) => {
        const cellStyle = useMemo(() => ({
            maxWidth: '150px',
            padding: '2px 6px 4px',
            background: '#FFF',
        }), []);

        const selectionStyle = useMemo(() => ({
            height: '27px',
            borderRadius: '10px',
            fontSize: '10px !important',
            border: '1px solid #EDEDEE',
            backgroundColor: 'transparent',
        }), []);

        const menuItemStyle = useMemo(() => ({
            color: '#00286D',
            fontWeight: '600',
            fontFamily: 'Switzer',
            fontSize: '8px !important',
        }), []);

        const inputStyle = useMemo(() => ({
            borderRadius: '10px',
            width: '100% !important',
            fontSize: '10px !important',
            maxWidth: '100% !important',
            minHeight: '27px !important',
            border: '1px solid #EDEDEE',
            padding: '5px 10px !important',
        }), []);

        const datePickerStyle = useMemo(() => ({
            width: '100% !important',
            minWidth: '65px !important',
            maxWidth: '100% !important',
        }), []);

        const handleSelectionChange = useCallback((column, value) => {
            filterTable(column, value, 'Immediately');
        }, [filterTable]);

        const handleInputChange = useCallback((column, value) => {
            filterTable(column, value, 'Stop');
        }, [filterTable]);

        const renderCellContent = useCallback((el) => {
            switch (true) {
                case el.text === 'Data Status':
                    return (
                        <Selection
                            onChange={(e) => handleSelectionChange(el.column, e.target.value)}
                            options={[{ value: 'ACT', label: 'Active' }, { value: 'DEL', label: 'Deleted' }]}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            selectBaseProps={{ sx: selectionStyle }}
                            menuItemProps={{ sx: menuItemStyle }}
                            selectInnerStyle={{ backgroundColor: 'transparent' }}
                        />
                    );

                case el.dataType === 'boolean' || el.dataType === 'bit':
                    return (
                        <Selection
                            onChange={(e) => handleSelectionChange(el.column, e.target.value)}
                            options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            selectBaseProps={{ sx: selectionStyle }}
                            menuItemProps={{ sx: menuItemStyle }}
                            selectInnerStyle={{ backgroundColor: 'transparent' }}
                        />
                    );

                case el.column === 'glStatusName' || el.column === 'glStatus':
                    return (
                        <Selection
                            onChange={(e) => handleSelectionChange(el.column, e.target.value)}
                            options={[
                                { value: 'Success', label: 'Success' },
                                { value: 'Pending', label: 'Pending' },
                                { value: 'Failed', label: 'Failed' },
                            ]}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            selectBaseProps={{ sx: selectionStyle }}
                            menuItemProps={{ sx: menuItemStyle }}
                            selectInnerStyle={{ backgroundColor: 'transparent' }}
                        />
                    );

                case el.column === 'category':
                    return (
                        <Selection
                            onChange={(e) => handleSelectionChange(el.column, e.target.value)}
                            options={[
                                { value: 'OR', label: 'OR' },
                                { value: 'PV', label: 'PV' },
                                { value: 'RV', label: 'RV' },
                                { value: 'TP', label: 'TP' },
                                { value: 'TR', label: 'TR' },
                                { value: 'ME', label: 'ME' },
                            ]}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            selectBaseProps={{ sx: selectionStyle }}
                            menuItemProps={{ sx: menuItemStyle }}
                            selectInnerStyle={{ backgroundColor: 'transparent' }}
                        />
                    );

                case el.dataType === 'datetime':
                    return (
                        <CustomDatePicker
                            onChange={(e) => handleSelectionChange(el.column, e)}
                            formControlProps={{ sx: datePickerStyle }}
                            inputBaseProps={{ sx: inputStyle }}
                            customDateProps={{ toolbarPlaceholder: '' }}
                        />
                    );

                default:
                    return (
                        <Input
                            onKeyDown={(e) => handleKeyDownInput(e, el)}
                            onChange={(e) => handleInputChange(el.column, e.target.value)}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            inputBaseProps={{ sx: inputStyle }}
                        />
                    );
            }
        }, [selectionStyle, menuItemStyle, datePickerStyle, inputStyle, handleSelectionChange, handleInputChange]);

        return (
            <tr style={{ borderBottom: '1px solid #E9E9EA' }}>
                <td></td>
                {
                    columnData.map((el, index) => (
                        <td
                            key={index}
                            hidden={el.column === 'noInfoss' && !isInfossNoList ? true : el.hidden}
                            align={el.textAlign}
                            style={cellStyle}
                        >
                            {renderCellContent(el)}
                        </td>
                    ))
                }
            </tr>
        );
    });

    const formatValue = (value, dataType) => {
        if (dataType === 'datetime' || dataType === 'date') {
            if (value !== "0001-01-01T00:00:00" && new Date(value).getFullYear() !== new Date(defaultDate).getFullYear()) {
                const tempDate = new Date(value);
                return tempDate.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
            } else {
                return '-';
            }
        } else if (dataType === 'currency') {
            return new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(`${value}`);
        } else if (value === true) {
            return 'Yes';
        } else if (value === false) {
            return 'No';
        } else {
            return value;
        }
    };

    const TableBodyRow = React.memo(({
        el,
        index,
        handleClick,
        handleDoubleClick,
        SelectedData,
        uniqueKey,
        columnData,
        handleFailedGL,
        dataMap,
        uniqueCol,
        customAction,
        tableAccess
    }) => {
        const [isHovered, setIsHovered] = useState(false);
        const [anchorEl, setAnchorEl] = useState(null);
        const divRef = useRef(null);

        const tempStyle = useMemo(() => {
            if (SelectedData && el && SelectedData[uniqueKey] === el[uniqueKey]) {
                return "bg-infoss text-white";
            } else {
                return "text-dark";
            }
        }, [SelectedData, el, uniqueKey]);

        const tempTextStyle = useMemo(() => {
            if (SelectedData && el && SelectedData[uniqueKey] === el[uniqueKey]) {
                return "selected-text";
            } else {
                return "normal-text";
            }
        }, [SelectedData, el, uniqueKey])

        return (
            <tr
                key={index}
                className={tempStyle}
                onClick={() => handleClick(dataMap[index])}
                onDoubleClick={() => handleDoubleClick(dataMap[index])}
                style={{ fontSize: '14px', background: '#FFF', padding: '24px 8px !important', alignItems: 'center', height: '27.09px', borderBottom: '1px solid #FAFAFA' }}
            >
                <td>
                    <TickBox
                        key={el[props.uniqueKey]}
                        checked={props.dataCheck.filter(dt => dt[props.uniqueKey] === el[props.uniqueKey]).length > 0 ? true : false}
                        onChange={(e) => handleCheck(e, el)}
                        onClick={(e) => { handleCheck(e, el); props.setSelectedData(el); props.handleGetData !== undefined && props.handleGetData(el) }}
                        baseCheckboxProps={{
                            sx: {
                                padding: '0px 0px 0px 8px',
                                marginTop: '-3px'
                            }
                        }}
                        size={'small'}
                    />
                </td>
                {
                    Object.keys(el).map((headersEl, index) => {
                        const temp = formatValue(el[headersEl], columnData.find(el => el.column === headersEl)?.dataType);
                        const dataType = columnData.find(el => el.column === headersEl)?.dataType;

                        if (headersEl === uniqueKey && columnData.find(el => el.column === headersEl)?.hidden) return null;

                        if (dataType?.includes('custom') === false) {
                            if (headersEl === 'rowStatus') {
                                return (
                                    <td
                                        key={index}
                                        align={columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '4px',
                                                display: 'flex',
                                                width: 'fit-content',
                                                alignItems: 'center',
                                                borderRadius: '32px',
                                                justifyContent: 'center',
                                                background: temp?.trim() === 'ACT' ? 'var(--Green-Button, #168444)' : 'var(--Red-Alert, #D20C0C)',
                                            }}
                                        >
                                            <Icon
                                                iconName={temp?.trim() === 'ACT' ? 'active' : 'inactive'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#EAEAEA',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                )
                            }
                            else if (headersEl === 'documentStatus' && temp !== '' && temp !== undefined) {
                                return (
                                    <td
                                        key={index}
                                        align={columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '75px',
                                                alignItems: 'center',
                                                borderRadius: '32px',
                                                padding: '4px 12px 4px',
                                                justifyContent: 'center',
                                                border: temp === 'Submitted' ? '1.5px solid var(--Orange-Outline-1, #FEA51E)' : temp === 'Draft' ? '1.5px solid #585858' : '1.5px solid #007330',
                                                background: temp === 'Submitted' ? 'rgba(254, 165, 30, 0.15)' : temp === 'Draft' ? 'rgba(88, 88, 88, 0.15)' : 'rgba(0, 115, 48, 0.15)',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontWeight: '600',
                                                    fontStyle: 'normal',
                                                    fontFamily: 'Nunito',
                                                    lineHeight: '12px !important',
                                                    color: temp === 'Submitted' ? 'var(--Orange-Outline-1, #FEA51E)' : temp === 'Draft' ? '#585858' : '#007330',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>
                                    </td>
                                )
                            }
                            else if (headersEl === 'glStatusName' || headersEl === 'glStatus') {
                                return (
                                    <td
                                        key={index}
                                        align={'center'}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                gap: '10px',
                                                height: '18px',
                                                padding: '10px',
                                                display: 'flex',
                                                borderRadius: '4px',
                                                alignItems: 'center',
                                                width: 'fit-content',
                                                justifyContent: 'center',
                                                background: temp === 'Failed' ? 'var(--Mjr-blue-4, #BE2957)' : 'transparent',
                                                boxShadow: isHovered && temp === 'Failed' ? '0px 4px 4px 0px rgba(0, 0, 0, 0.20)' : null,
                                            }}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                            disabled={temp === 'Failed' ? false : true}
                                            onClick={() => temp === 'Failed' ? handleFailedGL(el) : null}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '11px',
                                                    userSelect: 'none',
                                                    fontStyle: 'normal',
                                                    fontFamily: 'Nunito',
                                                    lineHeight: 'normal',
                                                    fontWeight: temp === 'Failed' ? '700' : '400',
                                                    cursor: temp === 'Failed' ? 'pointer' : 'default',
                                                    textDecorationLine: temp === 'Failed' ? 'underline' : 'none',
                                                    color: temp === 'Success' ? '#168444' : temp === 'Failed' ? '#FFFFFF' : temp === 'On Progress' ? '#F68C00' : '#F68C00',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>
                                    </td>
                                )
                            }
                            else {
                                return (
                                    <td
                                        key={index}
                                        align={columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <Typography
                                            className={tempTextStyle}
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontFamily: 'Switzer',
                                                fontSize: '10px',
                                                fontStyle: 'normal',
                                                lineHeight: '140%',
                                                letterSpacing: '0.1px',
                                            }}
                                        >
                                            {temp}
                                        </Typography>
                                    </td>
                                )
                            }
                        }
                        else {
                            const uniqueMark = uniqueCol?.includes('-') ? uniqueCol?.split('-')[0] : uniqueCol
                            if (!dataType?.includes(uniqueMark)) return null;

                            const customData = {
                                statusName: {
                                    'Ready to Request': {
                                        textColor: '#FFF',
                                        backgroundColor: '#6692DE',
                                        isDropDown: false,
                                    },
                                    'Requested': {
                                        textColor: '#F68C00',
                                        backgroundColor: '#FEF3E5',
                                        isDropDown: !uniqueCol.includes('Request') && !uniqueCol.includes('Import') && tableAccess?.[uniqueCol]?.Requested.access ? true : false,
                                        dropDownValue: tableAccess?.[uniqueCol]?.Requested.dropDown
                                    },
                                    'Generated': {
                                        textColor: '#168444',
                                        backgroundColor: '#EBFDFF',
                                        isDropDown: false,
                                    },
                                },
                            };

                            const content = customData[headersEl]?.[temp];

                            if (content === undefined) return null;

                            const open = Boolean(anchorEl);
                            const id = open ? 'simple-popover' : undefined;

                            const handleClickPopOver = () => {
                                setAnchorEl(divRef.current);
                            };

                            const handleClose = () => {
                                setAnchorEl(null);
                            };

                            return (
                                <td
                                    key={index}
                                    align={columnData.find(el => el.column === headersEl)?.textAlign}
                                    style={{
                                        padding: '0px 10px',
                                        whiteSpace: "nowrap",
                                        verticalAlign: 'middle',
                                    }}
                                >
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <div
                                            ref={divRef}
                                            style={{
                                                display: 'flex',
                                                padding: '2px 6px',
                                                alignItems: 'center',
                                                gap: '8px',
                                                borderRadius: '4px',
                                                background: content.backgroundColor,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    overflow: 'hidden',
                                                    color: content.textColor,
                                                    textOverflow: 'ellipsis',
                                                    fontFamily: 'Switzer',
                                                    fontSize: '8px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    lineHeight: '150%',
                                                    letterSpacing: '0.32px',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>

                                        <IconButton
                                            sx={{ marginLeft: '4px' }}
                                            onClick={
                                                (event) => {
                                                    event.stopPropagation();
                                                    handleClickPopOver();
                                                }
                                            }
                                            hidden={!content.isDropDown}
                                            size='small'
                                        >
                                            <Icon
                                                iconName='arrow-down'
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#00286D',
                                                    sx: {
                                                        height: '8px',
                                                        width: '8px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            sx={{
                                                flexDirection: 'column',
                                                borderRadius: '4px',
                                                marginTop: '4px',
                                                boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                                            }}
                                        >
                                            {
                                                content.dropDownValue?.map((el, index) => (
                                                    <Typography
                                                        key={index}
                                                        sx={{
                                                            color: el.color,
                                                            fontFamily: 'Switzer',
                                                            fontSize: '8px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '150%',
                                                            letterSpacing: '0.32px',
                                                            padding: '8px 16px',
                                                            cursor: 'pointer',
                                                            borderBottom: index + 1 !== content.length ? '1px solid #E9E9EA' : 'none',
                                                            transition: 'color 0.2s ease-in-out',
                                                            '&:hover': {
                                                                color: el.hoverColor,
                                                            },
                                                        }}
                                                        hidden={el.hidden}
                                                        onClick={() => customAction(el.value, uniqueCol)}
                                                    >
                                                        {el.label}
                                                    </Typography>
                                                ))
                                            }
                                        </Popover>
                                    </Stack>
                                </td>
                            );
                        }
                    })
                }
            </tr >
        );
    });

    const memoizedColumnData = useMemo(() => props.columnData, [props.columnData]);

    const handleClick = (el) => {
        props.setSelectedData(el);

        // Call handleGetData only if it exists
        if (props.handleGetData) {
            props.handleGetData(el);
        }

        handleCheckOnClick(el);
    };


    return (
        <>
            <Table
                hover={!props?.static}
                className='table table-sm'
                style={{ '--bs-table-bg': '#FFF' }}
                onKeyDown={handleKeyDown}
            >
                <thead style={{ position: 'sticky', top: '-4px', 'color': '#FFF', background: '#FFF', zIndex: 1 }}>
                    {
                        props.columnData.length > 0 && (
                            <>
                                <TableHeaderRow columnData={props.columnData} SortData={SortData} CekSortStatus={CekSortStatus} />
                                <TableFilterRow columnData={props.columnData} filterTable={filterTable} />
                            </>
                        )
                    }
                </thead>

                <tbody className="text-muted">
                    {
                        props.dataTable.length > 0 ?
                            props.dataTable.map((el, index) => (
                                <TableBodyRow
                                    el={el}
                                    key={index}
                                    index={index}
                                    dataMap={props.dataMap}
                                    handleClick={handleClick}
                                    uniqueKey={props.uniqueKey}
                                    columnData={memoizedColumnData}
                                    SelectedData={props.SelectedData}
                                    handleDoubleClick={handleDoubleClick}
                                    handleFailedGL={props.handleFailedGL}
                                    uniqueCol={props.uniqueCol}
                                    customAction={props.customAction}
                                    tableAccess={props.tableAccess}
                                />
                            ))
                            :
                            <tr style={{ borderBottom: 'transparent', borderTop: 'transparent', userSelect: 'none', pointerEvents: 'none' }}>
                                <td colSpan={20} className="text-center py-3 text-muted">
                                    <div
                                        style={{
                                            display: 'flex',
                                            padding: '32px 0px',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '16px',
                                            borderRadius: '12px',
                                            background: '#FAFAFA',
                                        }}
                                    >
                                        <img
                                            src={noDataAvailable}
                                            alt="Preview"
                                            style={{ width: "80px", height: "auto", justifyContent: "center", alignItems: "center" }}
                                        />

                                        <Typography
                                            sx={{
                                                overflow: 'hidden',
                                                color: 'var(--Mjr-black-1, #747475)',
                                                textOverflow: 'ellipsis',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '140%',
                                                letterSpacing: '0.1px',
                                            }}
                                        >
                                            No Data Available
                                        </Typography>
                                    </div>
                                </td>
                            </tr>
                    }
                </tbody>
            </Table>
        </>
    )
}

export default TableCheck